.subpage{
    padding: 116px 0 160px;

    h2{
        font-family: Raleway, sans-serif;
        font-weight: 400;
        font-size: 62px;
        color: #333333;
        letter-spacing: 7.75px;
        text-align: center;
        margin: 100px auto 72px;
    }

    @media screen and (max-width: 1052px) {
        padding: 87px 0;

        h2{
            font-size: 45px;
            margin: 82px auto 62px;
        }
    }

    @media screen and (max-width: 615px) {
        padding: 59px 0 100px;

        h2{
            font-size: 28px;
            margin: 68px auto 40px;
        }
    }
}