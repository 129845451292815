.header.transition{
    transition: 0.7s;
    top: -116px;
}

.mainpage{
    
    section > .center:first-child{
        @media screen and (max-width: 1052px) {
            padding: 0 56px;    
        }
        @media screen and (max-width: 615px) {
            padding: 0 30px;
        }
    }
}

section.visual{
    overflow: hidden;
    position: relative;
    height: 100vh;
    background-color: #000;

    .visualVideo{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        min-width: 1920px;
        height: 100%;
        object-fit: cover;

        // @media screen and (max-width: 1052px) {
        //     height: 100vh;
        //     width: auto;
            
        // }
    }

    .inner-table{
        position: absolute;
        top: 0;
        left: 0;
        display: table;
        width: 100%;
        height: 100%;
    }
    .inner-tablecell{
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 64px;
    }

    .logoVisual{
        width: 329px;
        margin: 0 auto;

        @media screen and (max-width: 615px) {
            width: 180px;
        }
    }

    .visualNav{
        height: 64px;
        padding: 20px 0;
        border-top: 1px solid rgba(255,255,255,0.20);
        margin-left: -470px;
        position: absolute;
        bottom: 0;
        left: 50%;
        text-align: center;

        @media screen and (max-width: 1052px) {
            border-top: none;
        }

        .toggleNav{
            position: fixed;
            top: 26px;
            left: 29px;
            color: #fff;

            @media screen and (max-width: 615px) {
                margin: 0;
                top: 14px;
                left: 14px;
            }
        }
        
        ul{
            @media screen and (max-width: 1052px) {
                display: none;
            }
        }
        
        li{
            display: inline-block;
            margin-right: 36px;

            span, a{
                color: #fff;
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 2px;
                cursor: pointer;
            }
        }

        li:last-of-type{
            margin-right: 0;
        }
        
        
    }
}

section.introduction{
    padding: 148px 0 180px;
    background-image: url(../../public/assets/blurBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -120px top 50%;
    overflow: hidden;

    h2{
        font-size: 52px;
        color: #777;
        font-weight: 100;
        letter-spacing: -4px;
        line-height: 1.2;
        margin-bottom: 28px;

        b{
            color: #333;
            font-weight: 400;
        }
    }
    p{
        color: #a2a2a2;
        font-size: 21px;
        letter-spacing: -1.26px;
        line-height: 1.5;
        font-weight: 300;
        margin-bottom: 72px;

        b{
            font-weight: 400;
            color: #666;
        }
    }

    @media screen and (max-width: 1052px) {
        padding: 96px 0 116px;

        h2{
            font-size: 44px;
            margin-bottom: 24px;
        }

        p{
            font-size: 18px;
            margin-bottom: 52px;
        }
    }

    @media screen and (max-width: 615px) {
        padding-top: 72px;

        h2{
            font-size: 30px;
            margin-bottom: 18px;
        }

        p{
            font-size: 16px;
            margin-bottom: 40px;
        }
    }

    .images::-webkit-scrollbar{
        display: none;
    }

    .images{
        -ms-overflow-style: none;

        @media screen and (max-width: 1052px) {
            overflow: scroll;
        }
        
        ul{
            display: flex;

            @media screen and (max-width: 1052px) {
                padding: 0 10px;
            }
    
            li{
                margin-right: 20px;
    
                img{
                    width: 300px;

                    @media screen and (min-width: 1600px) {
                        width: 360px;
                    }                    
                }

                @media screen and (max-width: 1052px) {
                    margin-right: 15px;

                    img{
                        width: 248px;
                    }
                }
            }
            li:last-of-type{
                padding-right: 56px;

                @media screen and (max-width: 615px) {
                    padding-right: 30px;
                }

            }
        }
    }
}

section.businessArea{
    background-color: #fafafa;
    padding: 118px 0 128px;

    h3{
        font-family: 'Raleway', sans-serif;
        font-size: 24px;
        font-weight: 500;
        color: #666;
        margin-bottom: 45px;
        text-align: center;
        letter-spacing: 2.5px;
    }

    @media screen and (max-width: 615px) {
        padding: 80px 0;

        h3{
            font-size: 18px;
            margin-bottom: 28px;
        }
    }
    
    .buttonMore{
        margin: 0 auto;
    }
}

section.artists{
    padding: 118px 0 128px;
    
    @media screen and (max-width: 1052px) {
        padding: 100px 0;
    }

    @media screen and (max-width: 615px) {
        padding: 75px 0;
    }

    .center{
        display: flex;

        @media screen and (max-width: 615px) {
            display: block;
        }


        .leftText{
            width: 240px;

            @media screen and (max-width: 1052px) {
                padding-left: 10px;
            }

            @media screen and (max-width: 615px) {
               display: none;
            }
        

            ul{
                li{
                    margin-bottom: 20px;

                    a{
                        font-family: Raleway, sans-serif;
                        font-size: 24px;
                        letter-spacing: 3px;
                        font-weight: 300;
                        line-height: 27px;
                        transition: 0.5s;

                        @media screen and (max-width: 1052px) {
                            font-size: 20px;
                            letter-spacing: 1px;
                        }
                    }
                    a:hover{
                        font-weight: 600;
                    }
                }
            }
        }
    
        .rightImg{
            width: 700px;

            @media screen and (max-width: 1052px) {
                li.artistCard{
                    a{
                        height: 163px;
                    }
                }
            }
            @media screen and (max-width: 615px) {
                width: 100%;

                li.artistCard{
                    a{
                        height: 120px;
                        opacity: 1;
                        background-color: rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }
    }
}

section.album{
    padding-bottom: 128px;

    @media screen and (max-width: 1052px) {
        padding-bottom: 100px;

        .albumList{
            padding-bottom: 0;
        }
    }
}

section.press{
    padding-bottom: 160px;

    @media screen and (max-width: 1052px) {
        padding-bottom: 100px;
    }
    
    .pressList{
        margin-bottom: 56px;

        @media screen and (max-width: 1052px) {
            margin-bottom: 0;
        }
        @media screen and (max-width: 615px) {
            margin-bottom: 30px;
        }
        .pressCard:nth-child(n+4){
            display: none;
        }
    }
}

