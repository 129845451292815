.noticeList {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 60px;

    .noticeListItem {
        border-bottom: 1px solid #e5e5e5;

        a {
            display: block;
            padding: 22px 0;

            @media screen and (max-width: 615px) {
                padding: 16px 0;
            }
        }

        h3 {
            font-size: 18px;
            letter-spacing: -0.6px;

            @media screen and (max-width: 615px) {
                font-size: 14px;
            }
        }

        span {
            font-size: 14px;
            color: #999;

            @media screen and (max-width: 615px) {
                font-size: 11px;
            }
        }
    }
}

.noticeArticle {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    .titleArea {
        border-bottom: 1px solid #e5e5e5;
        padding: 22px 0 28px;

        @media screen and (max-width: 615px) {
            padding: 18px 0 20px;
        }

        h3 {
            font-size: 20px;
            color: #333;
            letter-spacing: -0.75px;

            @media screen and (max-width: 615px) {
                font-size: 16px;
            }
        }

        span {
            font-size: 16px;
            color: #999999;
            letter-spacing: -0.83px;

            @media screen and (max-width: 615px) {
                font-size: 12px;
            }
        }
    }
    .contentArea {
        padding: 50px 0;
        font-size: 16px;
        color: #666;
        letter-spacing: -0.48px;
        line-height: 1.6;

        @media screen and (max-width: 615px) {
            padding: 28px 0;
            font-size: 15px;
        }
    }
}
.noticeDetail {
    .center {
        overflow: hidden;

        @media screen and (max-width: 1052px) {
            padding: 0 56px;
        }

        @media screen and (max-width: 615px) {
            padding: 0 30px;
        }

        a.buttonSquare.list {
            float: right;
            margin: 0;
            margin-top: 25px;
        }
    }
}
