.location{

    .map{
        border: none;
        width: 100%;
        height: 480px;
    }

    p{
        text-align: right;
        margin-top: 10px;
        color: #999;

        @media screen and (max-width: 615px) {
            margin-top: 16px;
            font-size: 14px;
        }
    }
    
    .center{
        @media screen and (max-width: 615px) {
            padding: 0 30px;
        }
    }
}