ul.businessList{
    display: flex;
    width: 760px;
    margin: 0 auto 65px;

    @media screen and (max-width: 1052px) {
        width: 526px;
    }
    @media screen and (max-width: 615px) {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 40px;
    }

    li{
        width: 200px;
        margin-right: 80px;

        img{
            width: 116px;
            margin: 0 auto 25px;
        }

        h4{
            text-align: center;
            color: #666;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: -1.2px;
        }

        p{
            margin-top: 30px;
            font-size: 14px;
            color: #999;
            letter-spacing: -0.9px;
            line-height: 1.5;
        }

        @media screen and (max-width: 1052px) {
            width: 150px;
            margin-right: 38px;

            img{
                width: 90px;
                margin-bottom: 14px;
            }

            h4{
                font-size: 15px;
            }

            p{
                margin-top: 23px;
            }
        }

        @media screen and (max-width: 615px) {
            margin-right: 32px;
            width: 80px;
            display: inline-block;

            img{
                width: 80px;
                margin-bottom: 10px;
            }

            h4{
                font-size: 13px;
            }
        }
    }
    li:last-of-type{
        margin-right: 0;
    }
}