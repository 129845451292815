.company{
    .visual{
        background-size: cover;
        background-position: center 71%;
        width: 100%;
        height: 556px;
        padding-top: 223px;

        img{
            width: 275px;
            margin: 0 auto;
        }

        @media screen and (max-width: 1052px) {
            height: 400px;
            padding-top: 152px;

            img{
                width: 224px;
            }
        }

        @media screen and (max-width: 615px) {
            height: 250px;
            padding-top: 94px;

            img{
                width: 150px;
            }
        }
    }

    .content{
        padding-top: 140px;

        @media screen and (max-width: 1052px) {
            padding-top: 90px;
        }
        
        @media screen and (max-width: 615px) {
            padding: 60px 30px 0;
        }

        h2{
            font-family: Raleway, sans-serif;
            font-size: 44px;
            color: #333333;
            letter-spacing: 0.01px;
            text-align: center;
            line-height: 53px;
            margin-bottom: 38px;
            margin-top: 0;

            @media screen and (max-width: 1052px) {
                font-size: 34px;
                line-height: 45px;
                margin-bottom: 26px;
            }

            @media screen and (max-width: 615px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        
        .text{
            margin-bottom: 92px;

            @media screen and (max-width: 1052px) {
                margin-bottom: 60px;
            }

            p{
                font-size: 20px;
                color: #666666;
                letter-spacing: -0.9px;
                text-align: center;
                line-height: 31px;
                margin-bottom: 20px;
                font-weight: 300;

                @media screen and (max-width: 1052px) {
                    font-size: 17px;
                    letter-spacing: -1px;
                    text-align: center;
                    line-height: 28px;
                }

                @media screen and (max-width: 615px) {
                    font-size: 15px;
                    text-align: justify;
                    line-height: 26px;
                }

            }
        }

        h3{
            font-family: Raleway, sans-serif;
            font-size: 32px;
            font-weight: 600;
            color: #666;
            letter-spacing: 3.32px;
            text-align: center;
            margin-bottom: 48px;
            
            span{
                display: block;
            }

            span.ko{
                font-size: 16px;
                color: #AAAAAA;
                letter-spacing: -0.96px;
                font-weight: 400;
            }

            @media screen and (max-width: 1052px) {
                font-size: 20px;
                margin-bottom: 36px;

                span.ko{
                    font-size: 14px;
                }

            }
        }

        ul.businessList{
            @media screen and (max-width: 615px) {
                max-width: 360px;
                
                li{
                
                    display: flex;
                    width: 100%;
                    margin-bottom: 26px;
                    
                    figure{
                        width: 35%;

                        img{
                            width: 80%;
                            margin: 0;
                        }
                    }
                    
                    .desc{
                        width: 65%;
                        text-align: left;

                        h4{
                            font-size: 15px;
                            font-weight: 500;
                            text-align: left;
                        }

                        p{
                            font-size: 14px;
                            margin-top: 9px;
                        }
                    }
                }
            }
               
        }

        .partners{
            padding-top: 80px;

            @media screen and (max-width: 1052px) {
                padding-top: 28px;
            }
            
            ul{
                display: flex;
                flex-wrap: wrap;

                @media screen and (max-width: 615px) {
                    max-width: 460px;
                    margin: 0 auto;
                }

                li{
                    width: 172px;
                    margin-right: 20px;
                    margin-bottom: 30px;

                    @media screen and (max-width: 1052px) {
                        width: 20%;
                        margin-right: 0;
                        padding: 0 10px;
                    }

                    @media screen and (max-width: 615px) {
                        width: 33.33%;
                        margin-bottom: 14px;
                    }

                    img{
                        width: 100%;
                    }
                }
                li:nth-of-type(5n){
                    margin-right: 0;
                }
            }
        }
    }
}