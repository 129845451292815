.pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;

    @media screen and (max-width: 615px) {
      margin-top: 32px;
      margin-bottom: 20px;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul.pagination li {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: 1px solid #eee;
    margin: 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 615px) {
      width: 28px;
      height: 28px;
    }
  }
  
  ul.pagination li a {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #666;
    font-size: 14px;
    padding-top: 7px;

    @media screen and (max-width: 615px) {
      font-size: 12px;
      padding-top: 4px;
    }
  }
  
  // ul.pagination li.active a {
  //   color: white;
  // }

  ul.pagination li.active {
    background-color: #eee;
    border: none;
  }
  
  .page-selection {
    width: 48px;
    height: 30px;
    color: #666;
  }