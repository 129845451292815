.modal{
    position: fixed;
    z-index: 300;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    .modalBg{
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
    }

    .modalBox{
        width: 780px;
        padding: 85px;
        background-color: #fff;
        position: absolute;
        top: 100px;
        bottom: 100px;
        left: 50%;
        margin-left: -390px;
        background: #FFFFFF;
        box-shadow: 0 30px 60px 0 rgba(0,0,0,0.05);
        overflow: scroll;

        @media screen and (max-width: 1052px) {
            width: auto;
            margin-left: 0;
            top: 100px;
            right: 100px;
            bottom: 100px;
            left: 100px;
            padding: 50px;
        }
        @media screen and (max-width: 615px) {
            top: 40px;
            right: 40px;
            bottom: 40px;
            left: 40px;
            padding: 28px;
        }

        .close{
            position: fixed;
            right: 50px;
            top: 50px;
            border: none;
            width: 50px;
            height: 50px;
            overflow: hidden;
            background-color: transparent;

            img{
                width: 100%;
                cursor: pointer;
            }
            
            @media screen and (max-width: 1052px) {
                top: 50px;
                right: 50px;
            }
            @media screen and (max-width: 615px) {
                top: 6px;
                right: 6px;
                width: 36px;
                height: 36px;
            }
        }
    }
}

.shop + .modal{
    display: none;
}

.modal.alert{
    .modalBox{
        width: 300px;
        height: 150px;
        margin-left: -150px;
        padding: 30px;

        p{
            text-align: center;
            letter-spacing: -0.5px;
            margin-bottom: 20px;
        }
        
        .buttonSquare{
            font-weight: 400;
            color: #333;
            font-size: 15px;
            padding-top: 10px;
        }
    }
}