.pagePress{
    .subpage{
        padding-bottom: 100px;

        @media screen and (max-width: 1052px) {
            padding-bottom: 36px;
        }
        @media screen and (max-width: 615px) {
            padding-bottom: 80px;
        }
    }
}

.pressList{
    
    .center{
        display: flex;
        flex-wrap: wrap;
    }
    .pressCard{
        width: 300px;
        margin-right: 20px;
        margin-bottom: 60px;

        @media screen and (max-width: 1052px) {
            width: 33.33%;
            padding: 0 10px;
            margin-right: 0;
        }

        @media screen and (max-width: 615px) {
            width: 100%;
            margin-bottom: 5px;
            
            a{
                display: flex;
                flex-wrap: wrap;
            }
        }

        figure{
            width: 300px;
            height: 218px;
            background-size: cover;    
            background-position: center;
            background-color: #eee;
            margin-bottom: 20px;

            @media screen and (max-width: 1052px) {
                width: 100%;
                height: 160px;
            }

            @media screen and (max-width: 615px) {
                width: 40%;
                margin-right: 5%;
                height: 105px;
            }
        }

        @media screen and (max-width: 615px) {
            .text{
                width: 55%;
                
                h4{
                    margin-bottom: 4px;
                }
                p{
                    -webkit-line-clamp: 2;
                    font-size: 12.5px;
                    margin-bottom: 0;
                }
                .date{
                    font-size: 11px;
                }
            }
        }

        h4{
            font-size: 18px;
            margin-bottom: 10px;
            letter-spacing: -0.64px;
            font-weight: 600;

            @media screen and (max-width: 1052px) {
                font-size: 15px;
            }
        }

        p{
            font-size: 14px;
            color: #999;
            letter-spacing: -0.5px;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 10px;
        }
        
        .date{
            font-size: 12px;
            color: #999;
            letter-spacing: -0.3px;

        }
    }
    .pressCard:nth-child(3n){
        margin-right: 0;
    }

}