*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    text-align: left;
    font-family: 'Noto Sans KR', sans-serif;
    line-height: 1.4;
    color: #333;
}

img{
    display: block;
}

a{
    text-decoration: none;
    color: #333;
}

ol, ul, li{
    list-style: none;
}

.ellipsis-1{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-2{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ellipsis-3{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}