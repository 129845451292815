.albumList {
    padding-bottom: 42px;
    display: flex;
    flex-wrap: wrap;

    .albumCard {
        width: 300px;
        margin-right: 20px;
        margin-bottom: 48px;

        figure {
            width: 300px;
            height: 300px;
            margin-bottom: 13px;
            border: 1px solid #eee;

            img {
                width: 100%;
            }
        }

        @media screen and (max-width: 1052px) {
            width: 33.33%;
            margin-right: 0;
            padding: 0 10px;

            figure {
                width: 100%;
                height: auto;
            }
        }

        @media screen and (max-width: 615px) {
            width: 50%;
        }

        h4 {
            font-weight: 400;
            font-size: 13px;
            color: #999;
            letter-spacing: -0.39px;
        }
    }
    .albumCard:nth-child(3n) {
        margin-right: 0;
    }
}

.album {
    .subpage {
        padding-bottom: 112px;

        @media screen and (max-width: 1052px) {
            padding-bottom: 30px;
        }
    }
    .albumList {
        @media screen and (max-width: 615px) {
            padding-bottom: 0;
        }
    }
}

.albumDetail {
    padding-top: 86px;
    display: flex;

    @media screen and (max-width: 1052px) {
        padding: 56px 10px 0;
    }

    @media screen and (max-width: 615px) {
        display: block;
        padding-top: 30px;
    }

    .left {
        margin-right: 100px;

        @media screen and (max-width: 1052px) {
            width: 38%;
            margin-right: 6%;
        }

        @media screen and (max-width: 615px) {
            width: 100%;
        }

        figure {
            width: 380px;
            height: 380px;
            margin-bottom: 48px;
            border: 1px solid #eee;

            @media screen and (max-width: 615px) {
                margin-bottom: 20px;
            }

            img {
                width: 100%;
            }

            @media screen and (max-width: 1052px) {
                width: 100%;
                height: auto;
            }
        }

        .buttons {
            a {
                width: auto;
                display: inline-block;
                padding: 8px 19px 12px;
                margin-bottom: 12px;
                height: auto;
            }

            @media screen and (max-width: 615px) {
                display: flex;

                a {
                    width: 48%;
                    height: 47px;
                    margin-bottom: 28px;
                }
                a:first-of-type {
                    margin-right: 2%;
                }
                a:last-of-type {
                    margin-left: 2%;
                }
            }
        }
    }

    .right {
        width: 460px;

        @media screen and (max-width: 1052px) {
            width: 62%;
        }

        @media screen and (max-width: 615px) {
            width: 100%;
        }

        .headingBox {
            padding-bottom: 20px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 32px;

            h3 {
                font-size: 29px;
                font-weight: 500;
                letter-spacing: -0.6px;
                margin-bottom: 16px;
            }

            h4 {
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                letter-spacing: -0.45px;
            }

            @media screen and (max-width: 615px) {
                margin-bottom: 24px;

                h3 {
                    font-size: 24px;
                    margin-bottom: 5px;
                }
            }
        }

        .credit {
            color: #777;
            line-height: 1.6;

            @media screen and (max-width: 615px) {
                font-size: 15px;
            }
        }
    }
}
