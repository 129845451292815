ul.artistList{
    @media screen and (max-width: 1052px) {
        padding: 0 10px;
    }

    li.artistCard{
        margin-bottom: 16px;
        background-size: cover;
        background-position: center;
        display: table;
        width: 100%;

        a{
            transition: 0.5s;
            opacity: 0;
            display: table-cell;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 220px;
            color: #fff;
            text-align: center;
            vertical-align: middle;

            .en{
                font-family: Raleway, sans-serif;
                font-size: 40px;
                font-weight: 600;
                margin-right: 5px;
                letter-spacing: 5px;
            }
            .ko{
                font-size: 16px;
                font-weight: 300;
            }

            @media screen and (max-width: 1052px) {
                line-height: 1.2;
                
                .en{
                    font-size: 28px;
                    letter-spacing: 3px;
                }
                .ko{
                    font-size: 13px;
                }
            }
            
            @media screen and (max-width: 615px) {
                .en{
                    font-size: 20px;
                    letter-spacing: 1px;
                }
                .ko{
                    font-size: 11px;
                }
            }
        }

        a:hover, a.active {
            opacity: 1;
        }
    }
    li:last-of-type{
        margin-bottom: 0;
    }
}

.artist{
    .artistList{
        .artistCard{
            height: 400px;
            margin-bottom: 38px;

            @media screen and (max-width: 1052px) {
                height: 296px;
                margin-bottom: 28px;
            }

            @media screen and (max-width: 615px) {
                height: 120px;

                a{
                    height: 120px;
                }
            }

            a{
                opacity: 1;
            }
        }
    }
}

.artistDetail{
    .artistVisual{
        display: table;
        width: 100%;
        height: 620px;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: 1052px) {
            height: 440px;
        }       
        
        .artistVisual-cell{
            display: table-cell;
            background-color: rgba(0, 0, 0, 0.4);
            vertical-align: middle;
        }

        h2{
            color: #fff;
            margin: 0 auto 15px;

            .en{
                font-family: Raleway;
                font-weight: 600;
                font-size: 62px;
                letter-spacing: 7.75px;
            }
            .ko{
                font-size: 16px;
                letter-spacing: -0.72px;
                font-weight: 300;
            }

            @media screen and (max-width: 1052px) {
                .en{
                    font-size: 42px;
                    letter-spacing: 5.25px;
                }
                .ko{
                    font-size: 14px;
                }
            }

            @media screen and (max-width: 615px) {
                .en{
                    font-size: 29px;
                    letter-spacing: 3.63px;
                    display: block;
                    margin-bottom: -10px;
                }
            }
        }
        
        p{
            text-align: center;
            color: #fff;
            font-weight: 300;
            white-space: pre-wrap;

            @media screen and (max-width: 1052px) {
                font-size: 13px;
                letter-spacing: -0.6px;
            }
        }

    }

    .artistContent{
        padding-top: 110px;
        
        @media screen and (max-width: 1052px) {
            padding-top: 72px;

            .discography{

                @media screen and (max-width: 615px) {
                    .albumList{
                        padding-bottom: 5px;
                    }
                }
                .center{
                    padding: 0;
                }
            }
        }

        .platformButtonList{
            text-align: right;

            @media screen and (max-width: 1052px) {
                padding: 0 10px;
                margin-bottom: 28px;
            }
        }

        .history{
            .category{
                margin-bottom: 28px;
                
                h4{
                    font-size: 20px;
                    margin-bottom: 8px;
                    font-weight: 500;
                }
                .content{
                    font-size: 17px;
                    color: #777;
                    line-height: 1.6;
                    letter-spacing: -0.5px;
                }
            }
        }
        @media screen and (max-width: 615px) {
            .history{
                .category{
                    h4{
                        font-size: 17px;
                    }
                    .content{
                        font-size: 15px;
                    }
                }

                .category:last-child{
                    margin-bottom: 0;
                }
            }
            .history{
                padding: 0 10px;
            }
        }
    }
}

