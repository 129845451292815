.center{
    width: 940px;
    margin: 0 auto;

    @media screen and (max-width: 1052px) {
        width: 100%;
        padding: 0 46px;
    }
    @media screen and (max-width: 615px) {
        padding: 0 20px;
    }
}

.toggleNav{
    display: none;
    width: 48px;
    height: 48px;
    cursor: pointer;
    
    img{
        width: 48px;
    }

    @media screen and (max-width: 1052px) {
        display: block;
    }

    @media screen and (max-width: 615px) {
        width: 36px;
        height: 36px;
        margin-top: -5px;
        margin-left: -31px;

        img{
            width: 36px;
        }
    }
}

.buttonSquare{
    display: block;
    width: 92px;
    height: 38px;
    padding: 8px 0;
    line-height: 1;
    letter-spacing: 1.76px;
    border: 1px solid #dedede;
    text-align: center;
    color: #666;
    font-weight: 300;
    margin: 0 auto;
    transition: 0.5s;

    @media screen and (max-width: 615px) {
        width: 82px;
        height: 34px;
        font-size: 13px;
    }
}

.buttonSquare:hover{
    border: 1px solid #333;
    color: #333;
}

.titleSection{
    font-family: Raleway, sans-serif;
    font-weight: 300;
    font-size: 39px;
    letter-spacing: 4.91px;
    padding-bottom: 27px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 48px;

    @media screen and (max-width: 1052px) {
        font-size: 32px;
        padding: 0 0 20px;
        margin-bottom: 32px;
    }

    @media screen and (max-width: 615px) {
        font-size: 23px;
        padding-bottom: 14px;
        letter-spacing: 3px;
        margin-bottom: 24px;
    }
}

.none{
    background-color: #fafafa;
    padding: 100px 0;
    text-align: center;
    color: #999;
    margin-bottom: 100px;
    width: 100%;
}

.platformButtonList{
    margin-bottom: 36px;

    a{
        display: inline-block;
        width: 33px;
        height: 33px;
        background-color: #f4f4f4;
        border-radius: 50%;
        margin-left: 12px;

        img{
            width: 33px;
        }
    }
    a:first-child{
        margin-left: 0;
    }
}

.pbr{
    @media screen and (min-width: 616px) {
        display: block;
    }
}

.tbr{
    @media screen and (max-width: 1052px) {
        display: block;
    }
}

.mbr{
    @media screen and (max-width: 615px) {
        display: block;
    }
}

.hideOnmbl{
    @media screen and (max-width: 615px) {
        display: none;
    }   
}