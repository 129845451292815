.audition{
    // background-image: url(../../public/assets/blurBg.png);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: left -120px top 50%;
    
    .center{
        @media screen and (max-width: 1052px) {
            padding: 0 56px;
        }

        @media screen and (max-width: 615px) {
            padding: 0 30px;
        }
    }

    .auditionContent{
        border-top: 1px solid #e5e5e5;
        padding-top: 48px;
        text-align: center;
    
        h3{
            font-size: 36px;
            font-weight: 400;
            letter-spacing: -0.26px;
            margin-bottom: 24px;

            @media screen and (max-width: 615px) {
                font-size: 24px;
            }
        }
    
        p{
            font-size: 18px;
            color: #666;

            @media screen and (max-width: 615px) {
                font-size: 14px;
            }
        }
    }
}

