.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: #fff;
    width: 100%;
    height: 116px;
    border-bottom: 1px solid #e5e5e5;
    padding: 27px 0;

    @media screen and (max-width: 1052px) {
        height: 87px;
        padding: 20px 0;
    }

    @media screen and (max-width: 615px) {
        height: 59px;
        padding: 16px;
    }

    .headerLogo{
        width: 100px;
        float: left;
    
        @media screen and (max-width: 1052px) {
            width: 80px;
            float: none;
            margin: 0 auto;
        }

        @media screen and (max-width: 615px) {
            width: 65px;
        }
    }
    
    img.headerLogo{
        padding: 10px 0;
    
        @media screen and (max-width: 1052px) {
            padding: 7px 0;
        }
        @media screen and (max-width: 615px) {
            padding: 0;
        }
    }

    input#checkboxNav{
        display: none;
    }
    
    .nav{
        float: right;
    
        ul.mainNav{
            display: flex;
            padding: 5px 0;
            
            li{
                margin-left: 24px;
                padding: 15px 0;
    
                span, a{
                    color: #999;
                    font-family: 'Raleway', sans-serif;
                    font-size: 16px;
                    letter-spacing: 1px;
                    transition: 0.3s;
                    cursor: pointer;
                }

                span:hover, a:hover{
                    color: #333;
                }
                .activeNav{
                    color: #333;
                    font-weight: 500;
                }
    
                ul.subNav{
                    position: absolute;
                    border: 1px solid #e5e5e5;
                    background: #fff;
                    padding: 18px 18px 20px;
                    text-align: center;
                    margin-top: 12px;
                    display: none;
    
                    li{
                        margin-left: 0;
                        margin-bottom: 10px;
                        padding: 0;
    
                        a{
                            font-size: 15px;
                            

                        }
                    }

                    li:last-of-type{
                        margin-bottom: 0;
                    }
                }

                @media screen and (min-width: 1053px) {
                    ul.company{
                        margin-left: -29px;
                        width: 120px;
                    }
                    ul.artist{
                        margin-left: -78px;
                        width: 220px;
                    }
                    ul.article{
                        margin-left: -13px;
                        width: 100px;
                    }
                }

                ul.artist{
                    li{
                        // width: 100%;

                        // @media screen and (max-width: 1052px) {
                        //     width: 100%;
                        // }

                        a{
                            line-height: 1;

                            span{
                                display: inline-block;
                            }

                            span.artistKo{
                                display: none;
                            }
                        }
                        @media screen and (min-width: 616px) {
                            a:hover{
                                span.artistEn{
                                    display: none;
                                }
                                span.artistKo{
                                    display: inline-block;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
        ul.mainNav > li:hover > ul.subNav{
            display: block;
        }
    }
    

    // Tablet & Mobile

    @media screen and (max-width: 1052px) {
        .toggleNav{
            float: left;
            margin-left: -17px;

            @media screen and (max-width: 615px) {
                margin-top: -5px;
                margin-left: -21px;

                img{
                    width: 36px;
                }
            }
        }
        
        input#checkboxNav + nav{
            display: none;
        }
        input#checkboxNav:checked + nav{
            display: block;
        }
        .nav{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
    
            .bgNav{
                position: fixed;
                z-index: 100;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.05);
            }
            ul.mainNav{
                position: fixed;
                z-index: 101;
                width: 330px;
                height: 100%;
                background-color: #fff;
                padding: 48px;
                display: block;
                box-shadow: 20px 0 152px 0 rgba(0,0,0,0.10);

                @media screen and (max-width: 615px) {
                    width: 230px;
                    padding: 38px;
                }

                li{
                    margin-left: 0;
                    padding: 0;
                    margin-bottom: 24px;

                    @media screen and (max-width: 615px) {
                        margin-bottom: 17px;
                    }

                    a, .shop{
                        color: #333;
                        font-size: 23px;

                        @media screen and (max-width: 615px) {
                            font-size: 18px;
                        }
                    }
                    a:hover, .shop:hover{
                        font-weight: 600;
                    }

                    ul.subNav{
                        display: block;
                        border: none;
                        position: relative;
                        background: transparent;
                        text-align: left;
                        padding: 0;
                        margin-left: 18px;
                        margin-top: 15px;

                        @media screen and (max-width: 615px) {
                            margin-top: 10px;
                        }

                        li{
                            margin-bottom: 12px;
                            
                            a{
                                font-size: 16px;
                                color: #999;
                            }
                            a:hover, .activeNav, .activeNav > span{
                                color: #333;
                            }
                        }
                    }
                }
            }
        }
    }
}



