footer{
    padding: 64px 0;
    background-color: #fafafa;

    @media screen and (max-width: 1052px) {
        padding: 0;
    }
    
    .center{
        position: relative;

        @media screen and (max-width: 1052px) {
            padding: 48px 56px;
        }
        @media screen and (max-width: 615px) {
            padding: 36px 30px;
        }
    }

    .footerLogo{
        width: 109px;
        margin-bottom: 20px;

        @media screen and (max-width: 1052px) {
            width: 89px;
            margin-bottom: 13px;
        }
    }

    address{
        font-size: 12px;
        color: #999;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.3px;

        @media screen and (max-width: 1052px) {
            font-size: 11px;
        }
    }

    .sponsor{
        position: absolute;
        right: 0;
        bottom: 0;
        vertical-align: middle;
        
        @media screen and (max-width: 1052px) {
            position: relative;
            margin-top: 20px;
        }
        table{
            tr{
                td.title{
                    font-size: 13px;
                    color: #666;
                }
                td{
                    vertical-align: middle;

                    img{
                        display: inline-block;
                        margin-left: 10px;
                        opacity: 0.8;
                    }
                    .logo-mcst{
                        width: 114px;
                    }
                    .logo-kams{
                        width: 94px;
                    }

                    @media screen and (max-width: 1052px) {
                        .logo-mcst{
                            width: 92px;
                        }
                        .logo-kams{
                            width: 70px;
                        }
                    }
                }
            }
        }        
    }
}