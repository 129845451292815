.contact{
    .subpage{
        @media screen and (max-width: 1052px) and (min-width: 616px) {
            padding-bottom: 140px;
        }
    }
    form{
        max-width: 620px;
        width: 100%;
        margin: 0 auto;

        @media screen and (max-width: 1052px) {
            padding: 0 10px;
        }        

        input, textarea{
            width: 100%;
            resize: none;
            border: 1px solid #e5e5e5;
            background-color: #fafafa;
            padding: 10px;
            margin-bottom: 15px;
            font-family: 'Noto Sans KR', sans-serif;
            
        }
        input::placeholder,
        textarea::placeholder{
            color: #999;
        }
        input:focus,
        textarea:focus{
            outline: none !important;
        }

        textarea{
            height: 220px;
        }

        .buttonSquare{
            margin-top: 36px;
            background-color: transparent;
        }
    }
}